import React from "react";
import { TextField, Typography, makeStyles } from "@material-ui/core";

const UseStyles = makeStyles({
  title: {
    marginTop: "1rem",
  },
});

type InputFieldProps = {
  formState: {
    name: string;
    email: string;
    company: string;
    asiNumber: string;
    phoneNumber: string;
    designNotes: string;
  };
  onFormStateChange: (event: unknown) => void;
};

/*
 * formState -> React state object
 * onFormStateChange -> Event listener for state updates
 */
const InputFields = (props: InputFieldProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <div>
      <Typography variant="h5" className={Classes.title}>
        Contact Information
      </Typography>
      <TextField
        required
        name="name"
        label="Name"
        value={props.formState.name}
        onChange={props.onFormStateChange}
      />
      <TextField
        required
        name="email"
        label="Email"
        value={props.formState.email}
        onChange={props.onFormStateChange}
      />
      <TextField
        required
        name="company"
        label="Company"
        value={props.formState.company}
        onChange={props.onFormStateChange}
      />
      <TextField
        required
        name="asiNumber"
        label="ASI/PPAI Number"
        value={props.formState.asiNumber}
        onChange={props.onFormStateChange}
      />
      <TextField
        required
        name="phoneNumber"
        label="Phone Number"
        value={props.formState.phoneNumber}
        onChange={props.onFormStateChange}
      />
      <TextField
        multiline
        name="designNotes"
        label="Design Notes"
        value={props.formState.designNotes}
        onChange={props.onFormStateChange}
      />
    </div>
  );
};

export default InputFields;
