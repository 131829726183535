import React from "react";
import { Button, makeStyles } from "@material-ui/core";

const UseStyles = makeStyles({
  buttons: {
    margin: "1.5rem auto .5rem auto",
    textAlign: "center",

    "& Button": {
      margin: "auto .25rem auto .25rem",
      color: "lightgrey",
    },
  },

  primaryColor: {
    backgroundColor: "#AFBD21",

    "&:hover": {
      backgroundColor: "#AFBD21",
    },
  },

  secondaryColor: {
    backgroundColor: "#2B2B2B",

    "&:hover": {
      backgroundColor: "#2B2B2B",
    },
  },
});

type ImageButtonsProps = {
  handleCancel: (event: React.MouseEvent<HTMLButtonElement>) => void;
};

/*
 * handleCancel -> Event handler for cancel button
 */
const ImageButtons = (props: ImageButtonsProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <div className={Classes.buttons}>
      <Button
        variant="contained"
        type="submit"
        className={Classes.primaryColor}
      >
        Submit Request
      </Button>
      <Button
        variant="contained"
        onClick={props.handleCancel}
        className={Classes.secondaryColor}
      >
        Return To Product
      </Button>
    </div>
  );
};

export default ImageButtons;
