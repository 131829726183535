import React from "react";
import Breadcrumb from "../components/breadcrumb";
import ProductImage from "../components/product/product-image";
import Description from "../components/product/description";
import PriceTable from "../components/product/price-table";
import Options from "../components/product/options";
import InfoTable from "../components/product/info-table";
import ActionButtons from "../components/product/action-buttons";
import InfoTabs from "../components/product/info-tabs";
import Layout from "../components/layout";
import VirtualSample from "../components/virtual-sample";
import ImageDialog from "../components/product/image-dialog";
import GlobalConstants, { Product as Constants } from "../helpers/constants";
import { Grid, makeStyles, Typography } from "@material-ui/core";
import {
  GetComponentByName,
  GetDefaultVariant,
  IsUsaMade,
} from "../helpers/query-data";
import { graphql } from "gatsby";
import { UpdatePopularity } from "../helpers/popularity";
import {
  GQLQuery,
  GQLProduct,
  GQLPropertiesTableContent,
  GQLComponent,
  GQLImageContent,
  GQLImage,
  GQLSingleLineContent,
} from "../types";

const UseStyles = makeStyles({
  required: {
    color: "red",
  },
});

/*  Builds a path object to use for the page breadcrumb
 * - path -> The path of the current page
 * - nameComponent -> Crystallize single line component
 * - brandComponent -> Crystallize single line component
 */
const GetPathObj = (
  path: string,
  nameComponent: GQLComponent,
  brandComponent: GQLComponent
): Record<string, unknown> => {
  const PathObj = {};
  const BrandPath = "/products/" + path.split("/")[1];
  const NameCompContent = nameComponent.content as GQLSingleLineContent;
  const BrandCompContent = brandComponent.content as GQLSingleLineContent;

  PathObj[GlobalConstants.Home] = "/";
  PathObj[GlobalConstants.Products] = "/products";
  PathObj[BrandCompContent.text] = BrandPath;
  PathObj[NameCompContent.text] = null;

  return PathObj;
};

type ProductTemplateProps = {
  data: {
    crystallize: GQLQuery;
  };
};

const ProductTemplate = (data: ProductTemplateProps): JSX.Element => {
  const Classes = UseStyles();
  const Components = data.data.crystallize.catalogue.components;
  const Variants = (data.data.crystallize.catalogue as GQLProduct).variants;
  const Path = data.data.crystallize.catalogue.path;

  /* Components & Variants */
  const NameComponent = GetComponentByName(Components, GlobalConstants.Name);
  const BrandComponent = GetComponentByName(Components, GlobalConstants.Brand);
  const VariationsCompContent = GetComponentByName(
    Components,
    GlobalConstants.Variations
  ).content as GQLPropertiesTableContent;
  const DescriptionComponent = GetComponentByName(
    Components,
    GlobalConstants.Description
  );
  const InformationComponent = GetComponentByName(
    Components,
    GlobalConstants.Information
  );
  const InfoCompContent = InformationComponent.content as GQLPropertiesTableContent;
  const ImagesCompContent = GetComponentByName(
    Components,
    GlobalConstants.Images
  ).content as GQLImageContent;
  const DecorLocImagesCompContent = GetComponentByName(
    Components,
    GlobalConstants.DecorLocImages
  ).content as GQLImageContent;
  const DefaultVariant = GetDefaultVariant(Variants);

  /* Detemines if the product is made in the USA */
  const UsaMade = IsUsaMade(DefaultVariant);

  const HasVariations =
    VariationsCompContent?.sections &&
    VariationsCompContent?.sections.length > 0;
  const InitialState = {
    hasValidSelection: false,
    image: null as GQLImage,
    defaultImage: ImagesCompContent.images[0],
  };

  if (HasVariations) {
    VariationsCompContent.sections.forEach((section) => {
      InitialState[section.title] = "";
    });
  }

  const [OptionsState, SetOptionsState] = React.useState(InitialState);
  const handleChange = (event, node) => {
    const ChangedPropName = node.title;
    const NewPropValue = event.target.value;
    const AllPropNames = VariationsCompContent.sections.map(
      (section) => section.title
    );
    const Properties = InfoCompContent.sections[0].properties;
    const Sku = Properties.filter(
      (property) => property.key === GlobalConstants.Sku
    )[0].value;

    let imagePath = Sku.toLowerCase() + "-";
    let hasValidSelection = true;

    const Regex = /[ -_/]/g;
    for (const StateVar in OptionsState) {
      if (AllPropNames.includes(StateVar)) {
        if (StateVar === ChangedPropName) {
          imagePath += NewPropValue.replaceAll(Regex, "_").toLowerCase();
        } else {
          imagePath += OptionsState[StateVar].replaceAll(
            Regex,
            "_"
          ).toLowerCase();
        }

        const HasOption =
          OptionsState[StateVar] !== undefined &&
          OptionsState[StateVar] !== null &&
          OptionsState[StateVar].length > 0;
        if (StateVar !== ChangedPropName && !HasOption) {
          hasValidSelection = false;
        }

        imagePath += "-";
      }
    }

    imagePath = imagePath?.substring(0, imagePath.length - 1);
    const MatchingImages = ImagesCompContent.images.filter((image) =>
      image.url.includes(imagePath)
    );

    SetOptionsState((optionsState) => ({
      ...optionsState,
      [node.title]: event.target.value,
      image: MatchingImages.length > 0 ? MatchingImages[0] : null,
      hasValidSelection: hasValidSelection,
      defaultImage: ImagesCompContent.images[0],
    }));
  };

  /* */
  const DefaultDecorLocImage = DecorLocImagesCompContent?.images
    ? DecorLocImagesCompContent.images[0]
    : null;
  const DecorLocImage = {
    url: DefaultDecorLocImage?.url,
    altText: DefaultDecorLocImage?.altText,
  };
  const InfoTabsData: (GQLComponent & { url: string; altText: string })[] = [
    NameComponent as GQLComponent & { url: string; altText: string },
    DescriptionComponent as GQLComponent & { url: string; altText: string },
    InformationComponent as GQLComponent & { url: string; altText: string },
    DecorLocImage as GQLComponent & { url: string; altText: string },
  ];

  /* */
  const PathObj = GetPathObj(Path, NameComponent, BrandComponent);
  const [Open, SetOpen] = React.useState(false);
  const [ShowImageDialog, SetShowDialog] = React.useState(false);

  /* Updates the popularity for this item on Algolia */
  UpdatePopularity(data.data.crystallize.catalogue.id, DefaultVariant.sku);

  return (
    <div>
      <Layout>
        <Breadcrumb pathObj={PathObj}></Breadcrumb>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <ProductImage
                optionsState={{
                  hasValidSelection: OptionsState.hasValidSelection,
                  image: OptionsState.image as { url: string; altText: string },
                  defaultImage: OptionsState.defaultImage as {
                    url: string;
                    altText: string;
                  },
                }}
                setShowDialog={SetShowDialog}
                showExpandButton={true}
                usaMade={UsaMade}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Description
                    name={NameComponent}
                    description={DescriptionComponent}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PriceTable
                    priceComponent={GetComponentByName(
                      Components,
                      GlobalConstants.Price
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  {OptionsState.hasValidSelection === true &&
                    OptionsState.image === null && (
                      <Typography
                        variant="body2"
                        align="right"
                        className={Classes.required}
                      >
                        {Constants.NoCustomImageText}
                      </Typography>
                    )}
                  {HasVariations &&
                    VariationsCompContent.sections.map((node, index) => (
                      <Options
                        key={index}
                        value={OptionsState[node.title]}
                        handleChange={(event) => handleChange(event, node)}
                        variation={node}
                      />
                    ))}
                </Grid>
                <Grid item xs={12}>
                  <InfoTable infoComponent={InformationComponent} />
                </Grid>
                <Grid item xs={12}>
                  <ActionButtons setOpen={SetOpen} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <InfoTabs data={InfoTabsData} />
        </Grid>
        <ImageDialog
          optionsState={{
            hasValidSelection: OptionsState.hasValidSelection,
            image: OptionsState.image as { url: string; altText: string },
            defaultImage: OptionsState.defaultImage as {
              url: string;
              altText: string;
            },
          }}
          showImageDialog={ShowImageDialog}
          setShowDialog={SetShowDialog}
        />
        <VirtualSample
          open={Open}
          setOpen={SetOpen}
          name={NameComponent}
          optionsState={{
            hasValidSelection: OptionsState.hasValidSelection,
            image: OptionsState.image as { url: string; altText: string },
            defaultImage: OptionsState.defaultImage as {
              url: string;
              altText: string;
            },
          }}
          variant={DefaultVariant}
        />
      </Layout>
    </div>
  );
};

export const Query = graphql`
  query($slug: String!) {
    crystallize {
      catalogue(path: $slug) {
        ... on CRYSTALLIZE_Product {
          ...product
        }
      }
    }
  }

  fragment component on CRYSTALLIZE_Component {
    name
    content {
      ...singleLine
      ...richText
      ...imageContent
      ...paragraphCollection
      ...propertiesTable
    }
  }

  fragment imageContent on CRYSTALLIZE_ImageContent {
    images {
      ...image
    }
  }

  fragment image on CRYSTALLIZE_Image {
    url
    altText
    key
    variants {
      url
      width
      key
    }
  }

  fragment paragraphCollection on CRYSTALLIZE_ParagraphCollectionContent {
    paragraphs {
      title {
        ...singleLine
      }
      body {
        ...richText
      }
      images {
        ...image
      }
    }
  }

  fragment product on CRYSTALLIZE_Product {
    id
    name
    path
    variants {
      id
      sku
      name
      isDefault
      attributes {
        attribute
        value
      }
      images {
        url
      }
    }
    components {
      ...component
    }
  }

  fragment propertiesTable on CRYSTALLIZE_PropertiesTableContent {
    sections {
      ... on CRYSTALLIZE_PropertiesTableSection {
        title
        properties {
          key
          value
        }
      }
    }
  }

  fragment richText on CRYSTALLIZE_RichTextContent {
    plainText
  }

  fragment singleLine on CRYSTALLIZE_SingleLineContent {
    text
  }
`;

export default ProductTemplate;
