import React from "react";
import { ProductDetails as Constants } from "../../../helpers/constants";
import { Typography } from "@material-ui/core";

type ProductDetailsProps = {
  product: string;
  optionsState: {
    hasValidSelection: boolean;
    image: { url: string; altText: string };
    defaultImage: { url: string; altText: string };
  };
};

/*
 * product -> Name of the product
 * optionsState -> Selected product options
 */
const ProductDetails = (props: ProductDetailsProps): JSX.Element => {
  const Features = JSON.parse(JSON.stringify(props.optionsState));
  delete Features.defaultImage;
  delete Features.hasValidSelection;
  delete Features.image;
  const FeaturesArray = Object.entries(Features);

  return (
    <div>
      <Typography variant="h5">Product Details</Typography>
      <Typography variant="subtitle1">{props.product}</Typography>
      {FeaturesArray.map(([key, value], index) => (
        <Typography variant="body2" key={index}>
          {`* ${key}: ${
            value.toString().length > 0 ? value : Constants.NotSpecifiedText
          }`}
        </Typography>
      ))}
    </div>
  );
};

export default ProductDetails;
