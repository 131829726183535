import React from "react";
import { Typography, makeStyles } from "@material-ui/core";

const UseStyles = makeStyles({
  title: {
    padding: "1.5rem 0 1.5rem 0",
    backgroundColor: "#2B2B2B",
    color: "white",
  },
});

const VirtualSampleTitle = (): JSX.Element => {
  const Classes = UseStyles();

  return (
    <Typography variant="h5" className={Classes.title} align="center">
      Request Virtual Sample
    </Typography>
  );
};

export default VirtualSampleTitle;
