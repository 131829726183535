import React from "react";
import { DropzoneArea } from "material-ui-dropzone";
import { Typography, makeStyles } from "@material-ui/core";

const UseStyles = makeStyles({
  title: {
    marginTop: "1rem",
  },

  dropZone: {
    textAlign: "center",

    "& .MuiDropzoneArea-root": {
      backgroundColor: "lightgrey",
      minHeight: "5rem",
      height: "5rem",
      width: "65%",
      marginTop: "1rem",
    },
    "& .MuiDropzoneArea-text": {
      fontSize: "1rem",
      margin: ".5rem 0 .25rem 0",
    },
    "& .MuiDropzoneArea-icon": {
      height: "40px",
      width: "40px",
    },
    "& .MuiDropzonePreviewList-imageContainer": {
      margin: ".25rem auto 0 auto",
    },
  },

  previewChip: {
    minWidth: 160,
    maxWidth: 210,
  },
});

type ImageDropzoneProps = {
  onImageStateChange: (files: File[]) => void;
  imageLimit: number;
};

/*
 * onImageStateChange -> Event listener for state changes
 * imageLimit -> Number of images the user can select
 */
const ImageDropzone = (props: ImageDropzoneProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <div>
      <Typography variant="h5" className={Classes.title}>
        Upload Logo
      </Typography>
      <div className={Classes.dropZone}>
        <DropzoneArea
          onChange={props.onImageStateChange}
          showPreviews={true}
          showPreviewsInDropzone={false}
          useChipsForPreview
          previewGridProps={{ container: { spacing: 1, direction: "row" } }}
          previewChipProps={{ classes: { root: Classes.previewChip } }}
          previewText={""}
          acceptedFiles={["image/*"]}
          filesLimit={props.imageLimit}
        />
      </div>
    </div>
  );
};

export default ImageDropzone;
