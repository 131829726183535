import AlgoliaSearch, { SearchIndex } from "algoliasearch";

/* Initializes and returns the Algolia search index */
const GetAlgoliaIndex = (): SearchIndex => {
  const client = AlgoliaSearch(
    "D40R2811TE",
    "a429c54c5fe2d1483b2e22b9b705705c"
  );
  return client.initIndex("BrandBox");
};

/* Gets the curent popularity of a product from Algolia
 * - id -> ID of the product to query
 * - sku -> SKU of the product to query
 */
const GetCurrentPopularity = async (
  id: string,
  sku: string
): Promise<number> => {
  const Index = GetAlgoliaIndex();

  // Maybe there is a better way to create this type?
  type CustomHitType = { popularity: number };

  let currentPopularity = 0;
  await Index.search(sku).then(({ hits }) => {
    const MatchingHits: unknown[] = hits.filter((hit) => hit.objectID === id);
    const CustomMatchingHits = MatchingHits as CustomHitType[];

    if (CustomMatchingHits.length > 0 && CustomMatchingHits[0].popularity) {
      currentPopularity = CustomMatchingHits[0].popularity;
    }
  });

  return currentPopularity;
};

/* Updates the popularity for a product
 * - id -> ID of the product to update
 * - sku -> SKU of the product to update
 */
export const UpdatePopularity = (id: string, sku: string): void => {
  const Index = GetAlgoliaIndex();

  GetCurrentPopularity(id, sku).then((currentPopularity) => {
    Index.partialUpdateObjects([
      {
        objectID: id,
        popularity: currentPopularity + 1,
      },
    ]);
  });
};
