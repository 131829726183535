import React from "react";
import EditIcon from "@material-ui/icons/Edit";
import { Grid, Button, makeStyles } from "@material-ui/core";

const UseStyles = makeStyles({
  button: {
    marginRight: "0.5rem",
    background: "#AFBD21",

    "&:hover": {
      background: "#AFBD21",
    },
  },

  icon: {
    marginRight: "0.25rem",
  },
});

type ActionButtonsProps = {
  setOpen: (open: boolean) => void;
};

/*
 * setOpen -> Event handler for virtual sample dialog
 */
const ActionButtons = (props: ActionButtonsProps): JSX.Element => {
  const Classes = UseStyles();

  /* Opens the virtual sample dialog when clicked */
  const handleClick = () => {
    props.setOpen(true);
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <Button
          variant="contained"
          onClick={handleClick}
          className={Classes.button}
        >
          <EditIcon className={Classes.icon} />
          Virtual Sample
        </Button>
      </Grid>
    </Grid>
  );
};

export default ActionButtons;
