import React from "react";
import VirtualSampleTitle from "./virtual-sample/virtual-sample-title";
import VirtualSampleForm from "./virtual-sample/virtual-sample-form";
import VirtualSampleImage from "./virtual-sample/virtual-sample-image";
import InfoDialog from "./info-dialog";
import axios from "axios";
import { CloudBaseURL, InfoDialog as Constants } from "../helpers/constants";
import { Dialog, Grid } from "@material-ui/core";
import {
  GQLComponent,
  GQLProductVariant,
  GQLSingleLineContent,
} from "../types";

type VirtualSampleProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  name: GQLComponent;
  optionsState: {
    hasValidSelection: boolean;
    image: { url: string; altText: string };
    defaultImage: { url: string; altText: string };
  };
  variant: GQLProductVariant;
};

/*
 * open -> React state variable
 * setOpen -> Event handler for open
 * name -> Name component of the product
 * optionsState -> Selected product options
 * variant -> Default product variant
 */
const VirtualSample = (props: VirtualSampleProps): JSX.Element => {
  const HasCustomImage =
    props.optionsState.hasValidSelection && props.optionsState.image !== null;
  const Url = HasCustomImage
    ? props.optionsState.image.url
    : props.optionsState.defaultImage.url;
  const InitialFormState = {
    name: "",
    email: "",
    company: "",
    asiNumber: "",
    phoneNumber: "",
    designNotes: "",
  };

  /* State variable/fn for the form input fields */
  const [FormState, SetFormState] = React.useState(InitialFormState);
  const ResetFormState = () => SetFormState(InitialFormState);
  const OnFormStateChange = (event) => {
    const { name, value } = event.target;

    SetFormState((formState) => ({
      ...formState,
      [name]: value,
    }));
  };

  /* State variable/fn for customer supplied image artwork */
  const [ImageState, SetImageState] = React.useState([]);
  const OnImageStateChange = (newFiles) => SetImageState(newFiles);

  /* State variable/fn for virtual sample dialog window */
  const [InfoDialogState, SetInfoDialogState] = React.useState({
    open: false,
    title: "",
    message: "",
  });

  /* Closes the virtual sample dialog */
  const HandleCloseInfoDialog = () => {
    SetInfoDialogState((infoDialogState) => ({
      ...infoDialogState,
      open: false,
    }));
  };

  /* Cancels (closes) & submits the virtual sample dialog */
  const HandleCancel = () => props.setOpen(false);
  const HandleSubmit = async (event) => {
    const NameCompContent = props.name.content as GQLSingleLineContent;
    event.preventDefault();

    const Data = new FormData();
    Data.append("product", NameCompContent.text);
    Data.append("optionsState", JSON.stringify(props.optionsState));
    Data.append("formState", JSON.stringify(FormState));
    Data.append("image", ImageState[0]);

    axios
      .post(CloudBaseURL + "/virtual-sample", Data)
      .then(() => {
        props.setOpen(false);
        ResetFormState();
        SetInfoDialogState({
          open: true,
          title: Constants.SuccessTitle,
          message: Constants.SuccessMessage,
        });
      })
      .catch(() => {
        props.setOpen(false);
        SetInfoDialogState({
          open: true,
          title: Constants.FailureTitle,
          message: Constants.FailureMessage,
        });
      });
  };

  return (
    <div>
      <Dialog maxWidth="lg" open={props.open} onClose={HandleCancel}>
        <VirtualSampleTitle />
        <Grid container spacing={0}>
          <VirtualSampleForm
            formState={FormState}
            onFormStateChange={OnFormStateChange}
            onImageStateChange={OnImageStateChange}
            productName={(props.name.content as GQLSingleLineContent).text}
            optionsState={props.optionsState}
            dropzoneImageLimit={1}
            handleSubmit={HandleSubmit}
            handleCancel={HandleCancel}
          />
          <VirtualSampleImage image={Url} imageText={Url} />
        </Grid>
      </Dialog>
      <InfoDialog
        open={InfoDialogState.open}
        onClose={HandleCloseInfoDialog}
        title={InfoDialogState.title}
        message={InfoDialogState.message}
      />
    </div>
  );
};

export default VirtualSample;
