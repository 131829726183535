import React from "react";
import { Options as Constants } from "../../helpers/constants";
import { GQLPropertiesTableSection } from "../../types";
import {
  FormControl,
  InputLabel,
  Select,
  FormHelperText,
  MenuItem,
  makeStyles,
} from "@material-ui/core";

const UseStyles = makeStyles({
  formControl: {
    width: "100%",
    marginBottom: "0.35rem",
  },

  select: {
    "&:before": {
      borderColor: "lightgrey",
    },
    "&:after": {
      borderColor: "lightgrey",
    },
  },

  selectIcon: {
    fill: "lightgrey",
  },

  helperText: {
    fontSize: "1rem",
    fontWeight: "bold",
  },
});

type OptionsProps = {
  value: string;
  handleChange: (
    event: React.ChangeEvent<{
      name?: string;
      value: unknown;
    }>,
    child: React.ReactNode
  ) => void;
  variation: GQLPropertiesTableSection;
};

/*
 * value -> React state variable
 * handleChange -> Event handler for value state changes
 * variation -> Product variation options (e.g. Bottle Color)
 */
const Options = (props: OptionsProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <FormControl className={Classes.formControl}>
      <InputLabel focused={false}>{Constants.ChooseOptionText}</InputLabel>
      <Select
        required
        value={props.value}
        onChange={props.handleChange}
        className={Classes.select}
        inputProps={{ classes: { icon: Classes.selectIcon } }}
      >
        {props.variation.properties.map((node, index) => (
          <MenuItem key={index} value={node.key}>
            {node.value}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText className={Classes.helperText}>
        {props.variation.title}
      </FormHelperText>
    </FormControl>
  );
};

export default Options;
