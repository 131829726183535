import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { GQLComponent } from "../../types";
import { GetInfoDetails } from "../../helpers/query-data";
import {
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  Table,
} from "@material-ui/core";

const UseStyles = makeStyles({
  table: {
    marginBottom: "0",
  },

  tableCellHead: {
    background: "lightgrey",
    fontWeight: "bold",
    width: "35%",
  },

  noBorder: {
    borderBottom: "none",
  },
});

type InfoTableProps = { infoComponent: GQLComponent };

const InfoTable = (props: InfoTableProps): JSX.Element => {
  const Classes = UseStyles();
  const InfoDetails = GetInfoDetails(props.infoComponent);
  const PropLength = InfoDetails.properties.length;

  return (
    <TableContainer component={Paper} elevation={5}>
      <Table size="small" className={Classes.table}>
        <TableBody>
          {InfoDetails.properties.map((node, index) => {
            const isLast = index === PropLength - 1;
            return (
              <TableRow key={index}>
                <TableCell
                  className={`${Classes.tableCellHead} ${
                    isLast && Classes.noBorder
                  }`}
                >
                  {node.key}
                </TableCell>
                <TableCell className={`${isLast && Classes.noBorder}`}>
                  {node.value}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InfoTable;
