import React from "react";
import { Grid, DialogContent, makeStyles } from "@material-ui/core";

const UseStyles = makeStyles({
  imageWrapper: {
    textAlign: "center",
  },

  image: {
    maxWidth: "100%",
    maxHeight: "auto",
  },
});

type VirtualSampleImageProps = { image: string; imageText: string };

/*
 * image -> Virtual sample image to display
 * imageText -> Alt text to use with the image
 */
const VirtualSampleImage = (props: VirtualSampleImageProps): JSX.Element => {
  const Classes = UseStyles();

  return (
    <Grid item xs={12} md={6}>
      <DialogContent className={Classes.imageWrapper}>
        <img
          src={props.image}
          alt={props.imageText}
          className={Classes.image}
        />
      </DialogContent>
    </Grid>
  );
};

export default VirtualSampleImage;
