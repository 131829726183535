import React from "react";
import ProductDetails from "./virtual-sample-form/product-details";
import InputFields from "./virtual-sample-form/input-fields";
import ImageDropzone from "./virtual-sample-form/image-dropzone";
import FormButtons from "./virtual-sample-form/form-buttons";
import { Grid, DialogContent, makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  dialogForm: {
    backgroundColor: "lightgrey",

    "& .MuiTextField-root": {
      width: "100%",
    },
  },
});

type VirtualSampleFormProps = {
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  onFormStateChange: (event: unknown) => void;
  onImageStateChange: (files: File[]) => void;
  productName: string;
  optionsState: {
    hasValidSelection: boolean;
    image: { url: string; altText: string };
    defaultImage: { url: string; altText: string };
  };
  formState: {
    name: string;
    email: string;
    company: string;
    asiNumber: string;
    phoneNumber: string;
    designNotes: string;
  };
  dropzoneImageLimit: number;
  handleCancel: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void;
};

/*
 * formState -> React state variable
 * onFormStateChange -> Event listener for state changes
 * onImageStateChange -> Event listener for state changes
 * productName -> Name of the product
 * optionsState -> Selected product options
 * dropzoneImageLimit -> Max number of images to upload
 * handleSubmit -> Event handler for submit button
 * handleCancel -> Event handler for cancel button
 */
const VirtualSampleForm = (props: VirtualSampleFormProps): JSX.Element => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} className={classes.dialogForm}>
      <DialogContent>
        <form onSubmit={props.handleSubmit}>
          <ProductDetails
            product={props.productName}
            optionsState={props.optionsState}
          />
          <InputFields
            formState={props.formState}
            onFormStateChange={props.onFormStateChange}
          />
          <ImageDropzone
            imageLimit={props.dropzoneImageLimit}
            onImageStateChange={props.onImageStateChange}
          />
          <FormButtons handleCancel={props.handleCancel} />
        </form>
      </DialogContent>
    </Grid>
  );
};

export default VirtualSampleForm;
